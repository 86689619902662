import { useCallResultLabels } from "@/hooks/useCallResultLabels"
import { CallListItem } from "@/models/CallList"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
import { DataGrid, GridCallbackDetails, GridColDef, GridInputRowSelectionModel, GridRowSelectionModel, GridSortModel, jaJP } from "@mui/x-data-grid"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@/store/store"

type Props = {
  rowCount?: number,
  onChangeOptionalInfoOrder?: (columns: string[]) => void,
  rowSelectionModel?: GridInputRowSelectionModel, 
  onRowSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void,
  checkboxSelection?: boolean,
  showCallResults?: boolean,
  showCallAICallPage?: boolean,
  editMode?: boolean,
  optionalInfoOrder?: string[],
  handleChangePage?: (newPage: number) => void,
  handleChangeSort?: (sortModel: GridSortModel) => void,
  placeholderText?: string,
  hideCheckbox?: boolean,
  pageSize?: number
} & (
  {
    callListId?: undefined,
    callListItems: CallListItem[]   // 未アップロードのコールリストを表示する場合
  } | {
    callListId: string,             // フェッチしてきたものを表示する場合
    callListItems?: undefined
  }
)

const CallListTable = React.memo(({
  callListId,
  callListItems,
  onChangeOptionalInfoOrder,
  rowSelectionModel,
  rowCount,
  onRowSelectionModelChange,
  checkboxSelection = false,
  showCallResults = false,
  showCallAICallPage = false,
  editMode = false,
  optionalInfoOrder = [],
  handleChangePage,
  handleChangeSort,
  placeholderText,
  hideCheckbox,
  pageSize = 20
}: Props) => {
  const callResultLabels = useCallResultLabels()
  const prevToolbarScroller = useRef(null)
  const toolbarScroller = useRef(null)
  const dataGridScroller = useRef(null)
  const dataGridScrollerParent = useRef(null)
  const storedCallListItems = useSelector((state: RootState) => state.call.callListItems)
  const displayCallListItems = useMemo(() =>
    callListId ? Object.values(storedCallListItems)[callListId] : callListItems,
    [callListId, callListItems]
  )
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [displayPage, setDisplayPage] = useState(0)
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  // 全てのコールリストで表示するカラム
  const defaultColumns = useMemo(() => {
    const cols: GridColDef[] = [
      { field: "index", headerName: "番号", width: 48,  },
      { field: "companyName", headerName: "会社名", width: 200, editable: true },
      { field: "phoneNumber", headerName: "電話番号", width: 120, editable: true },
    ]

    // コール結果を表示する場合
    if(showCallResults) {
      cols.push({ field: "lastCallResult", headerName: "コール結果", width: 140, editable: false })
    }else if(showCallAICallPage ) {
      cols.splice(2, 0, { field: "lastCallResult", headerName: "コール結果", width: 120, editable: false })
    }

    return cols
  }, [showCallResults, showCallAICallPage])
  
  // callListItemsとcolumnsの同期
  useEffect(() => {
    if(!displayCallListItems)
      return    
    
    if(optionalInfoOrder.length) {
      const optionalColumns = optionalInfoOrder.map(key => ({
        field: key,
        headerName: key,
        width: 120,
        editable: true,
        sortable: false   // firestoreにindexがないためソート不可
      }))
      setColumns(defaultColumns.concat(optionalColumns))
      return
    }

    if(displayCallListItems[0] && displayCallListItems[0].optionalInfo) {
      const optionalColumns = Object.keys(displayCallListItems[0].optionalInfo).map(key => ({
        field: key, headerName: key, width: 120, editable: true
      }))
      setColumns(defaultColumns.concat(optionalColumns))
    } else {
      setColumns(defaultColumns)
    }
  }, [setColumns, displayCallListItems])

  const rows = useMemo(() => displayCallListItems.map((item, index) => {
    const defaultRow = {
      id: item.index + 1,
      index: item.index + 1,
      companyName: item.companyName,
      phoneNumber: item.phoneNumber,
      lastCallResult: callResultLabels[item.lastCallResult] || item.lastCallResult || "コール結果未登録"
    }
    
    if(item.optionalInfo) {
      return {
        ...defaultRow,
        ...item.optionalInfo
      }
    }
    return defaultRow
  }), [displayCallListItems])

  // columnの入れ替え
  const moveColumn = useCallback((oldIndex: number, newIndex: number) => {
    const newColumns = [...columns]
    const tmp = newColumns[oldIndex]
    newColumns[oldIndex] = newColumns[newIndex]
    newColumns[newIndex] = tmp
    setColumns(newColumns)
  }, [columns])

  useEffect(() => {
    if(onChangeOptionalInfoOrder)
      onChangeOptionalInfoOrder(
        columns
          .filter(column => !["index", "id", "lastCallResult", "companyName", "phoneNumber"].includes(column.field))
          .map(column => column.field)
      )
  }, [columns])

  // カラム入れ替え用のツールバー
  const customToolbar = useCallback(() => {
    return (
      <Box
        display="flex"
        maxWidth="100%"
        ref={toolbarScroller}
        mb={-1}
        mt={1}
        sx={{
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none"
          },
          "-ms-overflow-style": "none",
          scrollbarWidth: "none"
        }}
      >
        {
          checkboxSelection && (
            <Box minWidth={50} maxWidth={50}></Box>
          )
        }
        {
          editMode &&
          columns.map((column, k) => (
            <Box minWidth={column.width} maxWidth={column.width} key={column.field}>
              {
                k > defaultColumns.length - 1 && (
                  <>
                    <IconButton
                      size="small"
                      disabled={k === defaultColumns.length}
                      onClick={() => moveColumn(k, k-1)}
                    >
                      <KeyboardArrowLeft></KeyboardArrowLeft>
                    </IconButton>
                    <IconButton
                      size="small"
                      disabled={k === columns.length - 1}
                      onClick={() => moveColumn(k, k+1)}
                    >
                      <KeyboardArrowRight></KeyboardArrowRight>
                    </IconButton>
                  </>
                )
              }
            </Box>
          ))
        }
      </Box>
    )
  }, [columns, moveColumn, toolbarScroller, editMode])

  // DataGridとToolbarのスクロール同期
  useEffect(() => {
    if(!dataGridScrollerParent.current || !toolbarScroller.current)
      return
    dataGridScroller.current = dataGridScrollerParent.current.querySelector('.MuiDataGrid-virtualScroller');

    // toolbarのインスタンスが変わった時 (columnsが更新されて再レンダリングされた時) に
    // 再度 data grid のスクロールバーと同期する
    if(prevToolbarScroller.current && prevToolbarScroller.current !== toolbarScroller.current) {
      // console.log(prevToolbarScroller.current.scrollLeft, dataGridScroller.current.scrollLeft)
      toolbarScroller.current.scrollLeft = dataGridScroller.current.scrollLeft
    }

    // 同期用の関数
    const syncScroll1 = () => dataGridScroller.current.scrollLeft = toolbarScroller.current.scrollLeft
    const syncScroll2 = () => toolbarScroller.current.scrollLeft = dataGridScroller.current.scrollLeft
    toolbarScroller.current.addEventListener('scroll', syncScroll1);
    dataGridScroller.current.addEventListener('scroll', syncScroll2);

    prevToolbarScroller.current = toolbarScroller.current

    return () => {
      toolbarScroller.current?.removeEventListener('scroll', syncScroll1);
      dataGridScroller.current?.removeEventListener('scroll', syncScroll2);
    }
  }, [dataGridScrollerParent.current, toolbarScroller.current])

  return (
    <DataGrid
      ref={dataGridScrollerParent}
      columns={columns}
      rows={handleChangePage ? rows.slice(displayPage*pageSize, (displayPage+1)*pageSize) : rows}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: pageSize }
        }
      }}
      checkboxSelection={checkboxSelection}
      pageSizeOptions={[pageSize]}
      rowHeight={36}
      rowSelectionModel={rowSelectionModel}
      sx={{
        bgcolor: "#fff",
        mt: editMode ? 2 : 0,
        "& .MuiDataGrid-cellCheckbox": {
          display: hideCheckbox ? "none" : "inline-block"
        },
        "& .MuiDataGrid-columnHeader:first-of-type": {
          display: hideCheckbox && checkboxSelection ? "none" : "inline-block",
        },
        "& .MuiDataGrid-virtualScroller": {
          overflowX: "hidden",
          scrollbarColor: "#ddd #f6f6f6"
        },
        "& .MuiDataGrid-cell--textLeft:first-of-type": {
          minWidth: "50px !important",
          maxWidth: "200px !important",
        },
        "& .MuiTablePagination-selectLabel": { display: { xs: "none", md: "none", lg: "none", xl: "none" } },
        "& .MuiTablePagination-select": { display: { xs: "none", md: "none", lg: "none", xl: "none" } },
        "& .MuiTablePagination-selectIcon": { display: { xs: "none", md: "none", lg: "none", xl: "none" } }
      }}
      rowCount={handleChangePage ? rowCount : rows.length}
      onRowSelectionModelChange={onRowSelectionModelChange || (() => {})}
      editMode={"row"}
      slots={{
        toolbar: customToolbar,
        noRowsOverlay: () => <Box px={4} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
          ({ placeholderText || "コールリスト未選択" })
        </Box>
      }}
      paginationMode={handleChangePage ? "server" : "client"}
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      onPaginationModelChange={async (paginationModel) => {
        if(handleChangePage)
          await handleChangePage(paginationModel.page)
        setDisplayPage(paginationModel.page)
      }}
      onSortModelChange={async (sortModel) => {
        if(handleChangeSort)
          await handleChangeSort(sortModel)
        setSortModel(sortModel)
      }}
      sortModel={sortModel}
      sortingOrder={["asc", "desc"]}
    ></DataGrid>
  )
})

export default CallListTable